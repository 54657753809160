import React, { Fragment } from 'react';
import AppRoutes from './routes';
import Interceptors from './api/interceptors';
import '~/assets/css/global.css';

function AppContainer() {
    return (
        <Fragment>
            
            {/* App Screens */}
            <AppRoutes />
      
            {/* Axios Response Handler */}
            <Interceptors />

        </Fragment>
    );
}

export default AppContainer;