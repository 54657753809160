import React, { Suspense } from 'react';
import { Homepage, ROUTES, AboutUsPage, ContactPage, RangePage, ProductPage, DetailProductPage } from "./Routes";
import {  Routes, Route, BrowserRouter } from "react-router-dom";
import { basename } from '~/utils/useBaseName';

function AppRoutes() {
    return (
        <BrowserRouter basename={basename} >
            <Suspense>
                <Routes>
                    {/* <Route path={ROUTES.Login} index={true} element={<LoginPage />} /> */}
                    <Route exact={true} path={ROUTES.Homepage} index={true} 
                        element={<Homepage />} />
                    <Route path={ROUTES.About} element={<AboutUsPage />} />
                    <Route path={ROUTES.Contact} element={<ContactPage />} />
                    <Route path={ROUTES.Range} element={<RangePage />} />
                    <Route path={ROUTES.Product} element={<ProductPage />} />
                    <Route path={ROUTES.ProductDetail} element={<DetailProductPage />} />
                </Routes>
            </Suspense>
        </BrowserRouter>
    );
}

export default AppRoutes;