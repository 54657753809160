import { useEffect } from 'react';
import { API_URL } from './index';

const Interceptors = () => {
    // const [message, setMessage] = useState({
    //     popup: false,
    //     text: ''
    // });

    useEffect(() => {
        API_URL.interceptors.request.use((config) => {
            config.timeout = 30000;
            
            return config;
        });

        API_URL.interceptors.response.use(
            response => response,
            error => {
                // if (error.config.url === 'master/employee/update_mac_address') return;
                
                if (error.response?.data?.message) {
                    if (error.config.url !== 'token') {
                        // setMessage({
                        //     text: error.response?.data?.message,
                        //     popup: true,
                        // });
                    }
                }

                if (error.response.status === 500) {
                    // setMessage({
                    //     text: 'There is something wrong while loading data',
                    //     popup: true,
                    // });
                }
    
                return Promise.reject(error);
            }
        );
    }, []);
  
    // const onRequestClose = () => {
    //     setMessage({ popup: false, text: '' });
    // };

    // return your component below here
    return null;
};

export default Interceptors;