const { lazy } = require("react");

export const Homepage = lazy(() => import('~/screens/homepage'));
export const AboutUsPage = lazy(() => import('~/screens/about'));
export const ContactPage = lazy(() => import('~/screens/contact'));
export const RangePage = lazy(() => import('~/screens/range'));
export const ProductPage = lazy(() => import('~/screens/product'));
export const DetailProductPage = lazy(() => import('~/screens/product/detail'));

export const ROUTES = {
    Homepage: '/',
    About: '/about',
    Contact: '/contact',
    Range: '/range',
    Product: '/product',
    ProductDetail: '/product/:slug'
};