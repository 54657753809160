import React, { createContext, useReducer, useEffect } from 'react';
import propTypes from 'prop-types';
import Cookies from 'universal-cookie';

const initialCounterState = {
    isLogged: false,
    isLoadingApp: true
};

function counterReducer(state, action) {
    switch (action.type) {
        case 'LOADING_APP':
            return { ...state, isLoadingApp: false };
        case 'LOGOUT':
            return { ...state, isLogged: false };
        case 'LOGIN':
            return { ...state, isLogged: action.payload };
        default:
            return state;
    }
}

const CounterContext = createContext();

const ContextProvider = ({ children }) => {
    const cookies = new Cookies();
    const [state, dispatch] = useReducer(counterReducer, initialCounterState);

    function counterContextWrapper() {
        return {
            handleLogout: () => {
                dispatch({ type: 'LOGOUT' });
            },
            handleLogin: (val) => {
                dispatch({ type: 'LOGIN', payload: val });
            },
            handleLoadingApp: () => {
                dispatch({ type: 'LOADING_APP' });
            },
        };
    }

    useEffect(() => {
        const loadAuth = async () => {
            const credential = await cookies.get('credential');
            
            if (credential === 1) {
                dispatch({
                    type: 'LOGIN',
                    payload: true
                });
            }
        };

        loadAuth();
    }, []);

    return (
        <CounterContext.Provider value={{ state, ...counterContextWrapper() }}>
            {children}
        </CounterContext.Provider>
    );
};

export { CounterContext, ContextProvider };

ContextProvider.propTypes = {
    children: propTypes.element
};
