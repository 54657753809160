import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import AppContainer from './config/AppContainer';

// Default theme
import '@splidejs/react-splide/css';
// or only core styles
import '@splidejs/react-splide/css/core'; 
import { ContextProvider } from './config/providers/ContextProviders';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
    <ContextProvider>
        <AppContainer />
    </ContextProvider>
);
